<script lang="ts" setup>
import { type Place } from "~/types/general";

const props = defineProps<{
  item: Place;
}>();

const localeRoute = useLocaleRoute();
</script>

<template>
  <div class="pa-4">
    <NuxtLink
      :prefetch="false"
      :to="localeRoute({ name: 'place', params: { place: item.route } })"
      :title="$t('fields.PARKING_' + item.type) + ' ' + item.title"
    >
      <NuxtPicture
        sizes="sm:180px lg:231px"
        :imgAttrs="{ class: 'rounded-2xl w-full' }"
        :src="item.asset_photo"
        :alt="item.title"
        loading="lazy"
      />
    </NuxtLink>
    <div class="mx-3 mt-2">
      <NuxtLink
        :prefetch="false"
        :to="localeRoute({ name: 'place', params: { place: item.route } })"
        class="text-lg font-semibold text-black"
        :title="$t('fields.PARKING_' + item.type) + ' ' + item.title"
      >
        <span class="text-base block font-medium text-gray">
          {{ $t("fields.PARKING_" + item.type) }}
        </span>
        {{ item.title }}
      </NuxtLink>
    </div>
  </div>
</template>
